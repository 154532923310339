import Pusher from 'pusher-js';
import { $POST, $PATCH, $DELETE } from '../plugins/Fetch';

export const ParseProgress = (attrs, config) => {
  return {
    open: attrs.processing,
    readout: '',
    processing: attrs.processing,
    processed: attrs.processed,
    channelName: `file-processing-${attrs.id}`,
    error: null,
    init() {
      const client = new Pusher(config.key, {
        cluster: 'us3',
      });
      const channel = client.subscribe(this.channelName);
      channel.bind('progress', (data) => {
        if (data.error) {
          this.error = data.error;
          return this.cancel();
        }
        this.readout = `${data.progress}/${data.total}`;
        if (data.done) window.location.reload();
      });
    },
    start() {
      this.error = null;
      this.readout = '';
      $POST(config.routes.start)
        .then((resp) => {
          return (this.processing = true);
          // if (resp.ok) return (this.processing = true);
          // if (resp.status === 409) alert('already processing');
        })
        .catch((err) => {
          debugger;
        });
    },
    cancel() {
      $PATCH(config.routes.cancel)
        .then((resp) => {
          this.processing = false;
        })
        .catch((err) => {
          debugger;
        });
    },
    deleteError(id) {
      $DELETE(config.routes.parse_errors + '/'.concat(id))
        .then((resp) => {
          this.$refs[`error-${id}`].remove();
        })
        .catch((err) => {
          debugger;
        });
    },
  };
};
