import { $GET, $POST, $PATCH, $DELETE } from '../plugins/Fetch';

const Reservations = (config) => {
  return {
    config,
    show_form: false,
    reservations: [],
    init() {
      (async () => {
        const resp = await $GET(config.routes.base);
        this.reservations = resp;
      })();
    },
    sorted() {
      return this.reservations.sort(
        (a, b) => a.date_timestamp - b.date_timestamp
      );
    },
    save(event, existingRecord = null) {
      const form = event.target.closest('form');
      const data = {
        date: form.querySelector('input[name="date"]').value,
        title: form.querySelector('input[name="title"]').value,
        notes: form.querySelector('textarea[name="notes"]').value,
      };
      if (!data.date) return;
      if (existingRecord) {
        this.update({ data, existingRecord });
      } else {
        this.create({ form, data });
      }
    },
    create: async function ({ form, data }) {
      const resp = await $POST(config.routes.base, { reservation: data });
      if (resp.errors) return alert(resp.errors.join('\n'));

      this.reservations.push({ ...resp, just_saved: true });
      this.handleJustSaved(resp.id);
      this.resetForm(form);
    },
    update: async function ({ data, existingRecord }) {
      const resp = await $PATCH(`${config.routes.base}/${existingRecord.id}`, {
        reservation: data,
      });
      if (resp.errors) return alert(resp.errors.join('\n'));
      const res = this.reservations.find((r) => r.id === existingRecord.id);
      Object.assign(res, { ...resp, just_saved: true });
      this.handleJustSaved(resp.id);
    },
    resetForm(form) {
      const inputs = form.querySelectorAll('input, textarea');
      inputs.forEach((i) => (i.value = ''));
    },
    handleJustSaved(resID) {
      const res = this.reservations.find((r) => r.id === resID);
      setTimeout(() => {
        if (res) res.just_saved = false;
      }, 2000);
    },
    remove: async function (id) {
      await $DELETE(`${config.routes.base}/${id}`);
      this.reservations = this.reservations.filter((r) => r.id !== id);
    },
  };
};

window.Reservations = Reservations;
