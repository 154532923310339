import { $GET } from '../plugins/Fetch.js';
export const CalendarAlerts = () => {
  const url = '/events/missing_times';
  return {
    loading: true,
    events: [],
    show: false,
    init() {
      $GET(url)
        .then((resp) => {
          this.events = resp;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  };
};
