import { $POST, $PATCH, $DELETE } from '../../plugins/Fetch.js';
import sorter from './sorter';
import Store from './store';

window.PageCategoriesManager = function (config) {
  const newCatIdBase = 'new-cat';

  return {
    config: config,
    ...{
      isManagerVisible: false,
      showForm: false,
      toast: new Toasted({
        theme: 'outline',
        duration: 3000,
      }),
    },
    init() {
      // Store.config = config;

      sorter.call(this);
    },
    getCatById(id) {
      return this.$store.categories.list.filter((c) => c.id === id)[0];
    },
    initNew() {
      let id = this.$id(newCatIdBase);
      this.$store.categories.insert({
        editting: true,
        order: 0,
        id: id,
        name: '',
        page_id: this.config.page.id,
      });
      this.focus(id);
    },
    editMe(id) {
      let cat = this.getCatById(id);
      cat.editting = true;
      this.focus(id);
    },
    focus(id) {
      // Wait for the x-transition
      setTimeout(() => {
        let input = document.getElementById(`cat-input-${id}`);
        input.focus();
      }, 110);
    },
    deleteMe(id) {
      const cat = this.getCatById(id);
      if (!cat.deleteConfirm) return (cat.deleteConfirm = true);
      $DELETE(this.config.routes.category + `/${id}`)
        .then((resp) => {
          this.$store.categories.remove(id);
          this.toast.success('Category was deleted');
        })
        .catch((err) => {
          this.showError(err);
        });
    },
    save(id, silent) {
      const cat = this.getCatById(id);
      if (cat.name.trim() === '') return;
      let method,
        url = this.config.routes.categories;
      if (`${cat.id}`.includes(newCatIdBase)) {
        method = $POST;
      } else {
        method = $PATCH;
        url = `${this.config.routes.category}/${cat.id}`;
      }
      method(url, {
        page_category: { ...cat },
      })
        .then((resp) => {
          if (resp.errors) {
            return this.showError(resp.errors);
          }
          cat.id = resp.id;
          cat.editting = false;
          if (!silent) this.toast.success('Category was saved');
        })
        .catch((err) => {
          this.showError(err);
        });
    },
    showError(message) {
      this.toast.error('Oops, ' + message);
    },
  };
};

document.addEventListener('alpine:init', () => {
  Alpine.store('categories', Store);
});
