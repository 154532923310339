// This is an alpinejs app

import * as Trix from 'trix';
import '@rails/actiontext';
import 'trix/dist/trix.css';
import '../css/trix.css';

window.TrixAttachments = function (config) {
  const state = {
    config: {
      ...config,
      uploads_allowed:
        config.uploads_allowed === undefined ? true : config.uploads_allowed,
    },
    element: null,
    dialog: {
      show: false,
    },
    uploading: false,
    video: null,
  };

  const onFileDrop = function (event) {
    if (!this.config.uploads_allowed) {
      event.preventDefault();
      alert('File upload is disabled.');
      return;
    }

    const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(
      event.file.type
    );

    // Continue w/ Trix default upload
    if (isImage) return;

    if (!videoEnabled) {
      alert('Create page before uploading video');
      event.preventDefault();
      return;
    }

    // Disrupt Trix attachment and do video upload process
    if (event.file.type.includes('video')) {
      event.preventDefault();
      if (this.uploading) return;
      this.uploading = true;
      return this.uploadVideo(event.file);
    }
  };

  const onAttachmentAdd = function () {
    this.uploading = false;
  };

  return {
    ...state,
    init() {
      document.addEventListener('trix-file-accept', onFileDrop.bind(this));
      document.addEventListener(
        'trix-attachment-add',
        onAttachmentAdd.bind(this)
      );

      // Save reference to trix toolbar, one Trix loads
      var listen = setInterval(function () {
        if (window.trixInit) {
          clearInterval(listen);
          this.element = window.trixElement;
        }
      }, 250);
    },
    uploadVideo(file) {
      VideoUpload(window.videos_path, file, {
        videoable_id: window.videoable_id,
        videoable_type: window.videoable_type,
        source_type: 'youtube',
        title: this.config.page_section_title,
      })
        .then((resp) => {
          if (resp.status === 201) {
            this.video = JSON.parse(resp.responseText);
            this.insertAttachment();
          } else {
            alert('could not upload ');
            this.uploading = false;
          }
        })
        .catch((err) => {
          alert('could not upload ' + err);
          this.uploading = false;
        });
    },
    insertAttachment() {
      var el = `<div style="padding: 1rem;" class="border border-gray-200 shadow-sm"><img src="https://img.youtube.com/vi/{ID}/hqdefault.jpg" alt=""></div>`;
      var attachment = new Trix.Attachment({
        sgid: this.video.sgid,
        content: el.replace(/{ID}/, this.video.source_id).trim(),
      });
      element.editor.insertAttachment(attachment);
      this.dialog.show = false;
    },
  };
};

document.addEventListener('trix-initialize', function (event) {
  // So the alpinejs app can grab reference to the element
  window.trixElement = event.target;
  window.trixInit = true;
});
