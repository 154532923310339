// This exports a Vue plugin and vanilla fetch functions

const fetcher = async (method, url, body) => {
  try {
    let opts = {
      method: method,
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': `${window.CSRF_TOKEN}`,
      },
    };
    if (body) {
      if (body instanceof FormData) {
        opts.body = body;
      } else {
        opts.body = JSON.stringify(body);
        opts.headers['Content-Type'] = 'application/json';
      }
    }
    const request = await fetch(url, opts);
    return await request.json();
  } catch (error) {
    return { errors: error.message };
  }
};

const fetchHtml = async (url) => {
  try {
    let opts = {
      method: 'GET',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'text/html',
        'X-CSRF-Token': `${window.CSRF_TOKEN}`,
      },
    };
    const request = await fetch(url, opts);
    return await request.text();
  } catch (error) {
    return { errors: error.message };
  }
};

const $GET_HTML = (url) => {
  return fetchHtml(url);
};

const $GET = (url) => {
  return fetcher('GET', url);
};
const $POST = (url, body) => {
  return fetcher('POST', url, body);
};
const $PATCH = (url, body) => {
  return fetcher('PATCH', url, body);
};
const $DELETE = (url, body) => {
  return fetcher('DELETE', url, body);
};

const VueFetch = {
  install(Vue) {
    Vue.prototype.$GET = (url, params) => {
      if (params) {
        const urlWithParams = `${url}?${new URLSearchParams(
          params
        ).toString()}`;
        return $GET(urlWithParams);
      }
      return $GET(url);
    };
    Vue.prototype.$POST = (url, body) => {
      return $POST(url, body);
    };
    Vue.prototype.$PATCH = (url, body) => {
      return $PATCH(url, body);
    };
    Vue.prototype.$DELETE = (url, body) => {
      return $DELETE(url, body);
    };
  },
};

export { $GET_HTML, $GET, $POST, $PATCH, $DELETE, VueFetch };
