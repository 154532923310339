export default function () {
  let list = this.$refs['list-el'];
  let sortable = Sortable.create(list, {
    handle: '.list-sort-handle',
    ghostClass: 'bg-gray-100',
    chosenClass: 'bg-green-100',
    dragClass: 'bg-green-300',
    onEnd: (evt) => {
      const categories = document.querySelectorAll('[data-cat-id]');
      categories.forEach((el, i) => {
        if (i !== parseInt(el.dataset.order)) {
          let cat = this.getCatById(parseInt(el.dataset.catId));
          cat.order = i;
          this.save(cat.id, true);
        }
      });
      this.toast.success('Order saved');
    },
  });
}
