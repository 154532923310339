import { $GET } from '../plugins/Fetch';

window.Alerts = (config) => {
  return {
    config,
    init() {
      for (let group in this.config.groups) {
        for (let metric in this.config.groups[group]) {
          this.config.groups[group][metric]['alerts'] = [];
          const { route } = this.config.groups[group][metric];
          this.get(route, group, metric);
        }
      }
    },
    get(url, group, metric) {
      $GET(url)
        .then((data) => {
          this.config.groups[group][metric]['alerts'] = data;
        })
        .catch(console.error);
    },
  };
};
