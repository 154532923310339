import Vue from 'vue/dist/vue.esm';

const NOTES = function () {
  Vue.config.productionTip = false;
  var app,
    config = JSON.parse(window.notes);
  let editForm;
  const init = function () {
    app = new Vue({
      el: '#notes',
      data: {
        loading: true,
        error: false,
        show: false,
        valid: false,
        willDelete: false,
        filters: [],
        notes: [],
        editing: { id: null, html: null },
      },
      created: async function () {
        fetch(config.path)
          .then(async (resp) => {
            const json = await resp.json();
            this.notes = json.map((str) => JSON.parse(str));
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.error = true;
          });
      },
      computed: {
        buttonClass() {
          return this.show ? 'block' : 'hidden';
          // return 'block'
        },
        formClass() {
          return this.show ? 'shadow-sm' : 'shadow-sm';
        },
      },
      methods: {
        focus() {
          this.show = true;
        },
        save(e) {
          e.preventDefault();
          if (this.$refs.body.value.length < 1) {
            return false;
          } else {
            this.$refs.form.submit();
          }
        },
        editClick(e) {
          if (e.target.dataset.cancel !== undefined) {
            e.preventDefault();
            this.show = false;
            this.editing = { id: null, html: null };
          }
          if (e.target.dataset.confirmDelete !== undefined) {
            e.preventDefault();
            document
              .getElementById('delete-note-container')
              .classList.remove('hidden');
          }
          return true;
        },
        edit: async function (id) {
          if (this.editing.id !== id) {
            let stream = await fetch(`/notes/${id}/edit`);
            let note = await stream.text();
            this.editing.html = note;
            this.editing.id = id;
            this.$nextTick(() => {
              let textarea = document
                .getElementById('edit-stage')
                .getElementsByTagName('textarea')[0];
              if (textarea.scrollHeight > textarea.clientHeight)
                textarea.style.height = textarea.scrollHeight + 10 + 'px';
            });
          }
        },
        toDate(ts) {
          let date = new Date(ts);
          let hrs =
            date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
          let ampm = date.getHours() >= 12 ? 'pm' : 'am';
          let time = `${hrs}:${date.getMinutes()}${ampm}`;
          return `${date.toDateString()} ${time}`;
        },
        parseLinebreaks(str) {
          return str.split(/\r?\n/g).join('<br />');
        },
      },
    });
  };

  return {
    init: init,
  };
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('notes') !== null) {
    NOTES().init();
  }
});
