window.EventBio = (config) => {
  return {
    config,
    show: false,
    progress: 0,
    saving: false,
    has_profile_image: config.has_profile_image,
    event: {
      id: config.event.id,
      bio: config.event.bio,
    },
    init() {
      const search = window.location.search;
      if (search === '?edit_bio=true') {
        this.show = true;
      }
    },
    toBio() {
      if (this.event.bio === null) return '';
      return this.event.bio.replace(/\n/g, '<br />');
    },
    save() {
      if (this.saving) return;
      this.saving = true;

      const request = new XMLHttpRequest();
      request.responseType = 'json';

      let body = new FormData();
      body.append('event[id]', this.event.id);
      body.append('event[bio]', this.$refs.bio.value);
      const fileInput = this.$refs.fileInput;
      if (fileInput.files[0]) {
        body.append('event[profile_image]', fileInput.files[0]);
        request.upload.addEventListener('progress', (e) => {
          this.progress = Math.round(
            (e.loaded / fileInput.files[0].size) * 100
          );
        });
      }

      request.open('PATCH', this.config.routes.event);
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('X-CSRF-Token', `${window.CSRF_TOKEN}`);

      request.addEventListener('error', (e) => {
        console.error(e);
        this.saving = false;
      });

      request.onload = (e) => {
        const resp = request.response;
        debugger;

        if (resp.profile_image_url) {
          const profileImages = document.querySelectorAll(
            '[data-profile-image]'
          );
          profileImages.forEach((el) => {
            el.src = resp.profile_image_url;
          });
          this.has_profile_image = true;
        }
        this.event.bio = resp.bio;
        this.saving = false;
      };

      request.send(body);
    },
  };
};
