import { $GET } from '../../plugins/Fetch.js';

export default {
  config: null,
  list: [],
  init() {
    if (!window.categoriesPath) return;
    $GET(window.categoriesPath)
      .then((resp) => {
        if (resp[0]) this.list = resp;
      })
      .catch((err) => {
        this.toast.error('Oops, ' + message);
      });
  },
  insert(data) {
    this.list.unshift(data);
  },
  remove(id) {
    this.list = this.list.filter((c) => c.id !== id);
  },
};
