import Pusher from 'pusher-js';

window.ContactsProgress = (contact_sync, config) => {
  // Enable pusher logging - don't include this in production
  // Pusher.logToConsole = true;
  return {
    config: config,
    syncing: contact_sync.syncing,
    total: 0,
    progress: 0,
    init() {
      const client = new Pusher(this.config.key, {
        cluster: 'us3',
      });
      const channelName = `${this.config.channel_name}-${contact_sync.id}`;
      const channel = client.subscribe(channelName);
      channel.bind(this.config.event_name, (data) => {
        if (data.done) return window.location.reload();
        for (const attr of ['total', 'progress']) {
          this[attr] = data[attr];
        }
      });
    },
  };
};
