import { $DELETE, $POST } from '../plugins/Fetch';

window.EventRoles = (user, config) => {
  return {
    role: {
      name: 'bride',
    },
    form: {
      show: false,
      other: false,
    },
    changed() {
      if (this.role.name === 'other') {
        this.form.other = true;
        this.role.name = '';
        setTimeout(() => {
          this.$refs.otherInput.focus();
        }, 10);
      }
    },
    save() {
      const existing = user.roles.map((role) => role.name).concat('');
      if (existing.includes(this.role.name.trim())) return;
      $POST(config.routes.roles.create, {
        event_role: {
          name: this.role.name,
          user_id: user.id,
          event_id: config.event_id,
        },
      }).then((resp) => {
        user.roles.push(resp);
      });
    },
    remove(id) {
      user.roles = user.roles.filter((role) => role.id !== id);
      $DELETE(config.routes.roles.create + '/'.concat(id)).then((resp) => {});
    },
    close() {
      this.form.show = false;
      this.form.other = false;
    },
  };
};
