import Pusher from 'pusher-js';

export const CalendarProgress = (calendar, config) => {
  // Enable pusher logging - don't include this in production
  // Pusher.logToConsole = true;
  return {
    syncing: calendar.syncing,
    readout: '',
    init() {
      const client = new Pusher(config.key, {
        cluster: 'us3',
      });
      const channelName = `${config.channel_name}-${calendar.id}`;
      const channel = client.subscribe(channelName);
      channel.bind(config.event_name, (data) => {
        if (data.done) window.location.reload();
        else this.readout = `${data.progress}/${data.total}`;
      });
    },
  };
};
