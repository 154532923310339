// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

import 'toastedjs/dist/toasted.min.js';
import 'toastedjs/dist/toasted.min.css';

import '../css/dist/application.css';
import '../css/inc/panels.css';
import '../css/inc/token.css';
import '../css/inc/transitions.css';
import '../css/inc/actiontext.css';

import '../css/vendor/pikaday.css';
import '../css/vendor/toasted.min.css';

// Primary event notes sidebar
import '../notes';

// event texters
import '../staff/texting';
import '../staff/status';

// Site mobile nav
import '../nav/site_nav';

// E-check handler
import '../banks';

// phone formatting site-wide
import '../util/phone';

// Alpine apps
import Alpine from 'alpinejs';
import { Calendar } from '../calendar';
import { CalendarAlerts } from '../calendar/alerts';
import { CalendarProgress } from '../calendar/progress';
import { Conflicts } from '../conflicts';
import { LatePayments } from '../events/payments';
import { ParseProgress } from '../pdf';
import { PdfGenerate } from '../pdf/generate';

import { ButtonTabs } from '../tabs/button';
import '../trix/attachments';
import '../payments/form';
import '../payments/public';
import '../messages';
import '../events/close';
import '../events/members';
import '../events/conflicts';
import '../contacts/progress';
import '../signatures';

import '../pages/categories';
import '../pages/search';
import '../pages/nav';

import '../events/bio';
import '../alerts';
import '../reservations';

// Business invites in admin (vendor directory)
import '../vendors/business_invites';

// Drag and drop util
import '../util/drag_and_drop';

// Packery draggable layout
import '../util/draggable_grid';
// Masonry layout
import '../util/masonry';
// QR codes
import '../util/qr_codes';

import '../util/clipboard';

// So they're available in the markup
window.Calendar = Calendar;
window.CalendarAlerts = CalendarAlerts;
window.CalendarProgress = CalendarProgress;
window.Conflicts = Conflicts;
window.LatePayments = LatePayments;
window.ParseProgress = ParseProgress;
window.PdfGenerate = PdfGenerate;
window.ButtonTabs = ButtonTabs;

window.Alpine = Alpine;

// Must go after all the apps are declared
Alpine.start();
