import { AsYouType } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';

const phones = document.querySelectorAll('[data-phone-format]');

const toPhone = (input) => {
  const parsed = parsePhoneNumber(`+1${input}`);
  if (parsed) return parsed.formatNational();
  return null;
};

phones.forEach(function (phoneInput) {
  const formatted = toPhone(phoneInput.value);
  if (formatted) phoneInput.value = formatted;

  phoneInput.addEventListener('input', function (event) {
    const { value } = event.target;
    if (value.length === 4) {
      // event.target.value = value;
      return;
    }

    const formatted = new AsYouType('US').input(value);
    event.target.value = formatted;
  });
});
