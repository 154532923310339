import Vue from 'vue/dist/vue.esm';
Vue.config.productionTip = false;

/*

Admin single payment form

*/

window.PAYMENT_FORM = function () {
  var app, vueData;
  const init = function () {
    vueData = JSON.parse(window.vueData);
    app = new Vue({
      el: '[data-payment]',
      data: { ...vueData, editMeta: false },
      mounted() {
        this.scheduledForDatepicker = new Pikaday({
          field: this.$refs.scheduledForDatepicker,
          onSelect: function (_date) {
            this.dateSelected('scheduled_for');
          },
        });

        this.capturedAtDatepicker = new Pikaday({
          field: this.$refs.capturedAtDatepicker,
          onSelect: function (_date) {
            this.dateSelected('captured_at');
          },
        });

        this.declinedAtDatepicker = new Pikaday({
          field: this.$refs.declinedAtDatepicker,
          onSelect: function (_date) {
            this.dateSelected('declined_at');
          },
        });
      },
      computed: {
        button() {
          let text = 'Save';
          let klass = 'bg-blue-300';
          var card = this.payment.method == 'card';
          if (this.state.confirmed) {
            text = card ? `Charge card now` : 'Charge bank now';
            klass = 'bg-green-300';
          } else if (this.state.scheduled && !this.payment.id) {
            text = 'Schedule payment';
          }
          return {
            klass: klass,
            text: text,
          };
        },
        payNowText() {
          let text = 'Pay now';
          if (['card', 'e_check'].indexOf(this.payment.method) == -1) {
            text = 'Mark as paid';
          }
          return text;
        },
      },
      watch: {
        'payment.method': function (is, was) {
          if (is !== 'e_check') this.payment.bank_id = null;
          if (is !== 'card') this.payment.card_id = null;
          if (is === 'card' || is === 'e_check') this.setMethod();
        },
      },
      methods: {
        setAmount(key) {
          this.$refs.amount.value = this.state[key];
        },
        datepickerBlurred(field, e) {
          this.dateSelected(field);
        },
        dateSelected(field) {
          if (field == 'scheduled_for') {
            this.state.scheduled =
              this.$refs.scheduledForDatepicker.value.length > 0;
            this.state.confirmed = false;
          } else if (field == 'captured_at') {
            this.state.captured_at = this.$refs.capturedAtDatepicker.value;
          } else if (field == 'declined_at') {
            this.state.declined_at = this.$refs.declinedAtDatepicker.value;
          }
        },
        setMethod() {
          switch (this.payment.method) {
            case 'card':
              let cards = this.cards.map(
                (obj) => obj.methods.filter((card) => card.default)[0]
              );
              if (this.payment.card_id !== null || cards.length === 0) return;
              this.payment.card_id = cards[0].id;
              break;
            case 'e_check':
              if (this.banks.length === 0) break;
              this.payment.bank_id = this.banks[0].methods[0].id;
              break;
          }
        },
        save: function (e) {
          if (this.state.stripe_charge_id != null) return;

          if (['card', 'e_check'].indexOf(this.payment.method) > -1) {
            // If status is `paid` at this point in flow, this is a new payment.
            let isNew = this.state.status === 'paid' && !this.state.scheduled;
            let payScheduledNow =
              this.state.status == 'scheduled' && !this.state.scheduled;
            if (!this.state.confirmed && (isNew || payScheduledNow)) {
              e.preventDefault();
              this.state.confirmed = true;
            }
          }
          return true;
        },
        pay: function (e) {
          this.state.scheduled = false;
          this.$refs.form.setAttribute(
            'action',
            this.$refs.form.dataset.payurl
          );
          this.save(e);
        },
      },
    });
  };

  return {
    init: init,
  };
};

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('[data-payment]') !== null) {
    PAYMENT_FORM().init();
  }
});
