import { getToken } from './login';
import { setOptions, selectHandler } from './account';

/**
 * @BANK
 * Called by the Plaid api after it's loaded.
 * See bottom of banks/_form
 *
 */

const BANK = async function () {
  let linkHandler;

  const App = {
    // 1. Get login link for the button
    token: await getToken(),
    onSuccess: async function (public_token, metadata) {
      // 2. User has logged in to their bank
      // Hydrate select so user can select which account they want to connect
      // e.g. checking, savings
      setOptions(public_token, metadata);

      // 3. setup and enable account select
      // user selects account
      selectHandler(metadata);

      // 4. user saves...
    },
    onExit: async function (err, metadata) {
      if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
        linkHandler.destroy();
        linkHandler = Plaid.create({
          ...App,
          token: await fetchLinkToken(),
        });
      }
      if (err != null) {
        debugger;
        // Handle any other types of errors.
      }
    },
  };

  // Kick everything off
  linkHandler = Plaid.create(App);

  document.getElementById('step-login-action').onclick = function () {
    if (this.getAttribute('disabled') === null) {
      linkHandler.open();
    }
  };
};

// Initiate controller
document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('[data-bank-form]')) BANK();
});
