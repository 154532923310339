import Vue from 'vue/dist/vue.esm';

window.CLOSE_EVENT = function () {
  var app, vueData;
  const init = function () {
    vueData = JSON.parse(window.vueData);
    app = new Vue({
      el: '#close',
      data() {
        return vueData;
      },
      methods: {
        refund: async function (id) {
          const payment = this.payments.filter((p) => p.id === parseInt(id))[0];
          if (payment.status === 'saving') return false;

          const data = {
            id: id,
            add_previous_refund: true,
            payment: {
              refund_amount: payment.amount_to_return,
            },
          };

          this.error = null;
          payment.status = 'saving';

          try {
            const response = await fetch(`${payment.action}`, {
              method: 'PATCH',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': `${window.CSRF_TOKEN}`,
              },
              body: JSON.stringify(data),
            });

            let respData = await response.json();

            if (response.status === 200) {
              payment.status = 'done';
              payment.refund_amount = respData.refund_amount;
              payment.amount_to_return = 0;
              this.return_amount = respData.return_amount;
            } else {
              this.error = respData.errors;
            }
          } catch (error) {
            this.error = error;
          }
        },
      },
    });
  };

  return {
    init: init,
  };
};

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('close')) {
    CLOSE_EVENT().init();
  }
});
