import { $PATCH } from '../../plugins/Fetch';

window.BusinessInvites = (config) => {
  const onIDChange = (ids) => {
    const url = new URL(window.location.href);
    url.searchParams.set('ids', ids.join(','));
    window.history.replaceState({}, '', url);
  };

  const setIDs = () => {
    const url = new URL(window.location.href);
    const ids = url.searchParams.get('ids');
    return ids ? ids.split(',').map((id) => parseInt(id)) : [];
  };

  return {
    config,
    ids: [],
    init() {
      this.$watch('ids', (ids) => {
        onIDChange(ids);
      });
      this.ids = setIDs();
    },
    toggle(id) {
      if (this.ids.includes(id)) {
        this.ids = this.ids.filter((i) => i !== id);
      } else {
        this.ids.push(id);
      }
    },
    toggleAll() {
      if (this.ids.length > 0) {
        this.ids = [];
      } else {
        this.ids = [...config.ids];
      }
    },
    ignoreAll() {
      const confirmed = confirm('Remove all selected vendors?');
      if (!confirmed) return;

      this.ids.forEach((id) => {
        this.ignore(id);
      });
    },
    ignoreOne(id) {
      const confirmed = confirm('Remove this vendor?');
      if (!confirmed) return;
      this.ignore(id);
    },
    ignore(id) {
      $PATCH(this.config.routes.vendor.replace(':id', id), {
        vendor: { referrable: false },
      }).then(() => {
        this.ids = this.ids.filter((i) => i !== id);
        this.removeRow(id);
      });
    },
    removeRow(id) {
      const rows = document.querySelectorAll(`[data-vendor-id="${id}"]`);
      rows.forEach((row) => {
        row.parentNode.removeChild(row);
      });
    },
  };
};
