const spinner = document.getElementById('new-bank-spinner');

const loading = (step) => {
  spinner.classList.add('active');
};

const next = (step) => {
  let element = document.getElementById(`step-${step}-action`);
  if (step === 'login') {
    element.classList.add('bg-blue-200');
    element.classList.add('cursor-pointer');
    element.classList.remove('cursor-not-allowed');
  }
  element.removeAttribute('disabled');
  spinner.classList.remove('active');
};

export { loading, next };
