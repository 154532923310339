document.addEventListener('DOMContentLoaded', () => {
  const clips = document.querySelectorAll('[data-clipboard]');
  clips.forEach((clip) => {
    if (navigator.clipboard) {
      clip.addEventListener('click', (e) => {
        e.preventDefault();
        const text = clip.getAttribute('data-clipboard');
        navigator.clipboard.writeText(text).then(() => {
          const originalText = clip.innerHTML;
          clip.textContent = 'Copied!';
          setTimeout(() => {
            clip.innerHTML = originalText;
          }, 2000);
        });
      });
    } else {
      clip.style.display = 'none';
    }
  });
});
