import { next } from './nav';
const getToken = async () => {
  // CSRF token generated by meta tags not working for this. idk why
  const form = document.getElementById('link-token-form');
  const linkTokenAuthToken = form.querySelector(
    '[name="authenticity_token"]'
  ).value;

  try {
    const response = await fetch(`${window.bankRoutes.create_link_token}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': `${linkTokenAuthToken}`,
      },
    });
    if (response.ok) {
      const responseJSON = await response.json();
      next('login');
      return responseJSON.link_token;
    }
    return null;
  } catch (error) {
    console.error;
    return error;
  }
};

export { getToken };
