window.PageSearch = function (config) {
  return {
    ...{
      config: config,
      results: [],
      query: '',
    },
    search(e) {
      this.query = e.target.value;
      if (this.query.length === 0) return (this.results = []);
      fetch(this.config.url + `?query=${this.query}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': `${window.CSRF_TOKEN}`,
        },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (!this.config.editting) {
            return (this.results = json.map((res) => ({
              ...res,
              href: `#${res.anchor}`,
            })));
          }
          this.results = json;
        })
        .catch((err) => {
          debugger;
        });
    },
    onResultClick() {
      this.clear();
    },
    clear() {
      this.query = '';
      this.results = [];
    },
  };
};
