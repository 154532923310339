import Packery from 'packery';

const DraggableGrid = (() => {
  const grids = document.querySelectorAll('[data-draggable-grid]');

  grids.forEach((grid) => {
    const packery = new Packery(grid, {
      itemSelector: '.grid-item',
      gutter: 1,
    });

    grid.querySelectorAll('img').forEach((img) => {
      img.addEventListener('load', () => {
        packery.layout();
        console.log('layout');
      });
    });
  });
})();
