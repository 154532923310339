import Vue from 'vue/dist/vue.esm';
var STAFF_TEXTING = (function () {
  const el = document.getElementById('staff-texting');
  if (el === null) return;
  Vue.config.productionTip = false;
  const app = new Vue({
    el: '#staff-texting',
    data: {
      pages: ['members', 'texts'],
      active: 'members',
      create: false,
      sections: {
        staff: {
          show: true,
        },
        security: {
          show: true,
        },
        vendors: {
          show: true,
        },
      },
    },
    methods: {
      toggle(section) {
        this.sections[section].show = !this.sections[section].show;
      },
      text: function (section) {
        return this.sections[section].show ? `+ ${section}` : 'Cancel';
      },
    },
    computed: {},
    watch: {
      active(is, was) {
        const newState = window.location.origin.concat(
          window.location.pathname,
          '#',
          is
        );
        window.history.replaceState({}, 'vendors', newState);
      },
    },
    created() {
      if (this.pages.indexOf(window.location.hash.substr(1)) > -1) {
        this.active = window.location.hash.substr(1);
      }
    },
  });
})();
