import SignaturePad from 'signature_pad';
import { $POST } from '../plugins/Fetch';

const resizeCanvas = (canvas, pad) => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  pad.clear(); // otherwise isEmpty() might return incorrect value
};

const defaultMessage = {
  show: false,
  type: 'success',
  text: '',
};

window.Signatures = (config) => {
  return {
    config,
    show: false,
    saving: false,
    showClearButton: false,
    signature: {
      user_id: null,
    },
    message: {
      ...defaultMessage,
    },
    init() {
      const makePad = () => {
        const canvas = this.$refs.canvas;
        this.signaturePad = new SignaturePad(canvas);

        this.signaturePad.addEventListener(
          'beginStroke',
          () => {
            this.showClearButton = true;
            this.resetMessage();
          },
          { once: false }
        );

        window.addEventListener('resize', () => {
          if (this.saving) return;
          resizeCanvas(canvas, this.signaturePad);
          this.showClearButton = false;
        });
        resizeCanvas(canvas, this.signaturePad);
      };

      this.$watch('show', (show) => {
        if (!show) return (this.signature.user_id = null);

        if (this.config.users.length === 1) {
          this.signature.user_id = this.config.users[0].id;
        }
        this.showClearButton = false;

        this.$nextTick(() => {
          makePad();
        });
      });

      this.$watch('signature.user_id', (show) => {
        if (this.signature.user_id) {
          this.clear();
          this.resetMessage();
        }
      });

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('signing') === 'true') {
        this.show = true;
      }
    },
    open() {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('signing', 'true');
      window.history.replaceState(null, null, '?' + urlParams.toString());
      this.show = true;
    },
    clear() {
      if (this.signaturePad) this.signaturePad.clear();
      this.showClearButton = false;
    },
    close() {
      if (this.saving) return;
      this.show = false;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('signing');
      window.history.replaceState(null, null, '?' + urlParams.toString());
    },
    save() {
      const valid = this.validate();
      if (!valid) return;
      if (this.saving) return;
      this.saving = true;
      $POST(this.config.routes.create, {
        signature: {
          user_id: this.signature.user_id,
        },
        event_id: this.config.event.id,
        signature_data: this.signaturePad.toDataURL(),
      })
        .then((resp) => {
          this.saving = false;
          this.message.text = 'Signature saved!';
          if (resp.making_contract) {
            this.message.subText =
              'Please allow a few minutes for the contract to be generated.';
          }
          this.message.type = 'success';
        })
        .catch((err) => {
          this.saving = false;
          this.message.text =
            'Could not save signature. Please contact us or try again.';
          this.message.type = 'error';
        })
        .finally(() => {
          this.clear();
          this.signature.user_id = null;
          this.message.show = true;
        });
    },
    validate() {
      let error = false;
      this.message.text = '';
      this.message.subText = '';

      if (this.signature.user_id === null) {
        this.message.text += 'Please indicate who is signing. ';
        error = true;
      }

      if (this.signaturePad.isEmpty()) {
        this.message.text += 'Please sign the document. ';
        error = true;
      }

      if (error) {
        this.message.type = 'error';
        this.message.show = true;
        return false;
      }

      return true;
    },
    resetMessage() {
      this.message = { ...defaultMessage };
    },
  };
};
