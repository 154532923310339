const DragAndDrop = (() => {
  const forms = document.querySelectorAll('[data-drag-and-drop]');
  forms.forEach((form) => {
    form.addEventListener('dragover', (event) => {
      event.preventDefault();
      form.classList.add('drag-over');
    });

    form.addEventListener('dragleave', () => {
      form.classList.remove('drag-over');
    });

    form.addEventListener('drop', (event) => {
      event.preventDefault();
      form.classList.remove('drag-over');

      const files = event.dataTransfer.files;
      const fileInput = form.querySelector('input[type="file"]');

      fileInput.files = files;
    });
  });
})();
