import { $POST, $GET_HTML } from '../../plugins/Fetch.js';
export default {
  card: {
    saving: false,
    data: null,
    form: {
      loading: false,
      html: '',
    },
  },
  options: {
    data: '',
    loading: false,
  },
  modal: {
    loading: false,
    show: false,
  },
  initNewCard() {
    this.card.form.html = '';
    this.modal.show = this.card.form.loading = true;
    $GET_HTML(this.config.routes.new_card)
      .then((resp) => {
        this.card.form.loading = false;
        this.card.form.html = resp;
        setTimeout(() => {
          Cards(true).then((resp) => {
            this.saveCard();
          });
        }, 500);
      })
      .catch((err) => {
        this.toast.alert(err);
      });
  },
  getCardOptions() {
    this.options.loading = true;
    $GET_HTML(this.config.routes.payment_method_options)
      .then((resp) => {
        this.options.data = resp;
      })
      .catch((err) => {
        this.toast.alert(err);
      })
      .finally(() => {
        this.options.loading = false;
      });
  },
  saveCard() {
    if (this.card.saving) return;
    this.card.saving = true;
    var form = document.getElementById('new_card');
    var inputs = form.querySelectorAll('input');
    let body = { card: {} };
    for (let input of inputs) {
      let name = input.name.replace(/card|\[|\]/g, '');
      if (name !== '' && name !== null) body.card[name] = input.value;
    }
    $POST(form.getAttribute('action'), body)
      .then((resp) => {
        this.card.data = resp;
        this.card.form.loading = this.modal.show = false;
        this.toast.success('Card saved');
        this.savePayment();
      })
      .catch((err) => {
        this.toast.alert(err);
      })
      .finally(() => {
        this.card.saving = false;
      });
  },
};
