import { $GET_HTML } from '../plugins/Fetch.js';
window.QRCode = (config) => {
  return {
    show: false,
    loading: true,
    get() {
      this.show = true;
      $GET_HTML(config.url)
        .then((resp) => {
          this.loading = false;
          this.$refs.container.innerHTML = resp;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };
};
