import Macy from 'macy';

const Masonry = () => {
  const containers = document.querySelectorAll('[data-masonry]');
  containers.forEach((container) => {
    const grid = new Macy({
      container: container,
      // trueOrder: false,
      // waitForImages: false,
      margin: 24,
      columns: 3,
      breakAt: {
        1200: 3,
        940: 2,
        520: 1,
      },
    });
  });
};

Masonry();
