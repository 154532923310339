import { $POST, $DELETE } from '../plugins/Fetch.js';

window.DateConflicts = (config, conflicts) => {
  return {
    saving: false,
    modal: {
      open: false,
    },
    note: {
      saving: false,
      body: '',
    },
    create(records) {
      if (this.saving) return;
      this.saving = true;

      $POST(config.url, {
        date_conflict: {
          ignore: true,
          act_ids: records.filter((r) => r.model == 'Act').map((r) => r.id),
          event_ids: records.filter((r) => r.model == 'Event').map((r) => r.id),
        },
      })
        .then((resp) => {
          if (resp.errors) {
            return;
          }
        })
        .catch((err) => {})
        .finally(() => {
          window.location.reload();
        });
    },
    destroy(id) {
      $DELETE(config.url + '/'.concat(id)).then((resp) => {
        window.location.reload();
      });
    },
    initNote(conflictId) {
      this.note.body = '';
      this.note.notable_id = conflictId;
      this.modal.open = true;
    },
    createNote() {
      $POST(config.notesUrl, {
        note: {
          event_id: config.eventId,
          notable_id: this.note.notable_id,
          notable_type: 'DateConflict',
          body: this.note.body,
        },
      })
        .then((resp) => {
          if (resp.errors) {
            return;
          }
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          window.location.reload();
        });
    },
  };
};
