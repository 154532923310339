import Vue from 'vue/dist/vue.esm';

window.MESSAGES = function () {
  var app;

  Vue.component('Message', {
    data: function () {
      return {
        visible: {
          body: false,
          opens: false,
          clicks: false,
        },
        details: null,
        loadingDetails: false,
        detailsLoaded: false,
      };
    },
    props: { data: Object },
    methods: {
      toggle(part) {
        this.visible[part] = !this.visible[part];
      },
      loadDetails(cm_message_id) {
        if (this.loadingDetails || this.detailsLoaded) return;
        this.loadingDetails = true;

        fetch(
          window.location.origin +
            window.location.pathname +
            '/details.json?cm_message_id=' +
            cm_message_id
        )
          .then((resp) => resp.json())
          .then((json) => {
            this.details = json;
            this.detailsLoaded = true;
          })
          .catch((err) => {
            this.error = true;
            this.errorMessage = err;
          })
          .finally(() => {
            this.loadingDetails = false;
          });
      },
    },
    template: document.getElementById('message-template').innerHTML,
  });

  const init = function () {
    app = new Vue({
      el: '#messages',
      data() {
        return {
          loading: false,
          loadingMessage: '',
          error: false,
          errorMessage: '',
          messages: [],
          passwordResets: [],
        };
      },
      methods: {
        getMessages(url) {
          return fetch(url)
            .then((resp) => {
              return resp.json();
            })
            .catch((err) => {
              this.loading = false;
              this.error = true;
              this.errorMessage = err;
            });
        },
      },
      created: async function () {
        this.loading = true;
        this.loadingMessage = 'Loading Notification history';
        this.getMessages(
          window.location.origin +
            window.location.pathname +
            '.json?cmSmartEmailKey=customer_notification'
        ).then((json) => {
          this.messages = json;
          this.loadingMessage = 'Loading Password Reset history';
          this.getMessages(
            window.location.origin +
              window.location.pathname +
              '.json?cmSmartEmailKey=reset_password'
          ).then((json) => {
            this.passwordResets = json;
            this.loading = false;
          });
        });
      },
    });
  };

  return {
    init: init,
  };
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('messages') !== null) {
    MESSAGES().init();
  }
});
