// alpinejs

window.PageNav = function () {
  const debounce = function (func, wait) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  };

  const isSmallScreen = function () {
    return document.documentElement.clientWidth < 768;
  };

  return {
    open: false,
    showMenuButton: false,
    init() {
      window.addEventListener(
        'resize',
        debounce(() => {
          this.showMenuButton = isSmallScreen();
          this.open = !isSmallScreen();
        }, 50)
      );
      this.showMenuButton = isSmallScreen();
      this.open = !this.showMenuButton;
    },
    onNavLinkClick(e) {
      const el = document.getElementById(e.target.dataset.section);

      this.open = !this.open;
      setTimeout(() => {
        // el.classList.add('bg-pink-500');
        el.scrollIntoView();
      }, 300);
      return false;
    },
  };
};
