import Pusher from 'pusher-js';
import { $GET } from '../plugins/Fetch.js';
export const LatePayments = (config) => {
  return {
    loading: true,
    show: false,
    events: [],
    init() {
      $GET(config.routes.payments);
      const client = new Pusher(config.pusher.key, {
          cluster: 'us3',
        }),
        channelName = `${config.channel_name}`,
        channel = client.subscribe(channelName);

      channel.bind(config.event_name, (data) => {
        this.events = data;
        this.loading = false;
      });
    },
  };
};
