export const ButtonTabs = (options) => {
  const storageKey = 'ellington-events-staff-setup';
  return {
    active: options[0],
    options: options,
    open: false,
    init() {
      let saved = localStorage.getItem(storageKey);
      if (saved) {
        const option = options.filter((option) => option.key === saved)[0];
        if (!option) {
          return this.clearItem(saved);
        }
        this.activate(option);
      }
    },
    activate(option) {
      this.active = option;
      this.open = false;

      localStorage.setItem(storageKey, option.key);
    },
    clearItem(key) {
      localStorage.removeItem(key);
    },
  };
};
