const PdfGenerate = (config) => {
  return {
    config,
    loading: true,
    link: null,
    error: null,
    init() {
      this.get();
    },
    get() {
      this.error = null;
      fetch(this.config.routes.generate)
        .then((resp) => {
          if (resp.ok) return resp.json();
          // todo handle error
          console.log('PdfGenerate get error', resp);
          this.error = resp.statusText;
        })
        .then((json) => {
          this.link = json.link;
        })
        .catch((err) => {
          console.log('PdfGenerate get error', err);
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  };
};

export { PdfGenerate };
