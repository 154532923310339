import { $PATCH } from '../plugins/Fetch.js';
import card from './inc/card';

// Card-related methods are in /inc/card.js

window.PublicPayments = function (config) {
  return {
    config: config,
    payment: {
      ...config.payment,
      saving: false,
    },
    toast: new Toasted({
      theme: 'outline',
      position: 'top-left',
      duration: 3000,
    }),
    ...card,
    init() {
      this.getCardOptions();
    },
    savePayment() {
      this.payment.saving = true;
      const form = document.getElementById('payment-form');
      $PATCH(form.getAttribute('action'), {
        payment: {
          card_id: this.card.data.id,
          amount: document.getElementById('payment-amount').value,
        },
      })
        .then((resp) => {
          if (resp.errors) {
            return this.toast.alert(resp.errors);
          }
          this.toast.success('Payment updated');
          this.getCardOptions();
        })
        .catch((err) => {
          this.toast.alert(err);
        })
        .finally(() => {
          this.payment.saving = false;
        });
    },
  };
};
