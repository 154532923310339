import { $GET, $PATCH } from '../plugins/Fetch';
import './roles';

window.EventMembers = (config) => {
  return {
    showSearch: false,
    users: [],
    results: [],
    init() {
      let url = config.routes.members.get;
      // Distinguish this route from the html format route. Fixes back-button issue
      // TODO: add this directly to Fetch.js for all GET requests
      url += '?any=key';
      $GET(url).then((resp) => {
        this.users = resp;
      });
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 10);
      } else {
        this.clear();
      }
    },
    clear() {
      this.results = [];
      this.$refs.searchInput.value = '';
    },
    search(event) {
      const query = event.target.value;
      if (query.length < 3) return (this.results = []);
      $GET(
        config.routes.search
          .concat('?customers=true&limit=10&query=')
          .concat(query)
      ).then((resp) => {
        let currentIds = this.results.map((user) => user.id),
          respIds = resp.map((user) => user.id),
          allIds = currentIds.concat(respIds);
        for (const id of allIds) {
          if (respIds.includes(id)) {
            if (!currentIds.includes(id)) {
              const user = resp.filter((user) => user.id === id)[0];
              this.results.push(user);
            }
          } else if (currentIds.includes(id)) {
            this.results = this.results.filter((user) => user.id !== id);
          }
        }
      });
    },
    add(id) {
      this.save({ user_id: id });
    },
    remove(id) {
      this.save({ user_id: id, remove: true });
    },
    save(data) {
      $PATCH(config.routes.members.update, data).then((resp) => {
        this.users = resp;
      });
    },
  };
};
