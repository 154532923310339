import { loading, next } from './nav';

let publicToken;

// Get access token for the selected account
const _getAccessToken = async () => {
  loading();

  // CSRF token generated by meta tags not working for this. idk why
  const form = document.getElementById('access-token-form');
  const authToken = form.querySelector('[name="authenticity_token"]').value;

  let id = document.getElementById('bank_account_id').value;
  try {
    let bank = await fetch(window.bankRoutes.access_token, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': `${authToken}`,
      },
      body: JSON.stringify({ public_token: publicToken, account_id: id }),
    });
    let resp = await bank.json();
    if (typeof resp.access_token === 'string') {
      document.getElementById('bank_access_key').value = resp.access_token;
      document.getElementById('bank_stripe_bank_account_token').value =
        resp.bank_account_token;
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

// Populate the select w/ each account option from their bank
const setOptions = async (public_token, metadata) => {
  loading();

  document.getElementById('public-token').value = public_token;
  document.getElementById('bank_institution_name').value =
    metadata.institution.name;

  publicToken = public_token;

  let frag = new DocumentFragment();
  for (let account of metadata.accounts) {
    let option = document.createElement('option');
    option.textContent = account.name;
    const fields = ['account_id', 'account_name', 'account_subtype'];
    for (let field of fields) {
      option.setAttribute(`data-${field}`, account[field.split('_')[1]]);
    }
    frag.appendChild(option);
  }
  document.getElementById('step-account-action').appendChild(frag);
};

const selectHandler = (metadata) => {
  // Set listeners
  document
    .getElementById('step-account-action')
    .addEventListener('change', async function () {
      const selected = this[this.selectedIndex];
      const fields = ['account_id', 'account_name', 'account_subtype'];
      for (let field of fields) {
        let input = document.getElementById(`bank_${field}`);
        input.value = selected.getAttribute(`data-${field}`);
      }
      // Get account access token for this account
      let token = await _getAccessToken();
      if (token.success) {
        // enable save button
        next('save');
      }
    });

  // enable select
  next('account');
};

export { setOptions, selectHandler };
