window.SiteNav = function () {
  return {
    menus: {},
    hideContainerOverflow(ref) {
      const action = this.menus[ref] ? 'add' : 'remove';
      // this.$refs.body.classList[action]('overflow-hidden');
      // this.$refs.main.classList[action]('overflow-hidden');
    },
    toggleSiteMobileMenu(ref) {
      if (this.menus[ref] === undefined) this.menus[ref] = false;
      this.menus[ref] = !this.menus[ref];
      this.hideContainerOverflow(ref);

      const el = this.$refs[ref];
      const callback = el.dataset.callback;
      const data = el.dataset.callbackData || '';
      if (typeof window[callback] === 'function') {
        window[callback](data);
      }
    },
  };
};
