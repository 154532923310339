import Pusher from 'pusher-js';
import { $GET } from '../plugins/Fetch.js';
export const Conflicts = (config) => {
  const url = '/events/conflicts';
  return {
    loading: true,
    show: false,
    conflicts: [],
    init() {
      $GET(url);
      const client = new Pusher(config.pusher.key, {
        cluster: 'us3',
      });
      const channelName = `${config.channel_name}`;
      const channel = client.subscribe(channelName);
      channel.bind(config.event_name, (data) => {
        this.conflicts = data;
        this.loading = false;
      });
    },
  };
};
